import React, { useState } from 'react';
import TableItemContextMenu from 'components/common/tableItemContextMenu';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ThreeDotsIcon } from "assets/icons/ico_three_dots_green.svg";
import { ReactComponent as DownloadIcon } from 'assets/icons/ico_download.svg';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { format, subDays } from 'date-fns';

const InvoiceItem = ({ invoice }) => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState();


    const invoiceDescription = (invoiceDate) => {
      if (!invoiceDate) return '';
      
      const date = subDays(new Date(invoiceDate), 10);
      const description = format(date, 'yyyy MMMM');

      invoice.description = description
      return description
  };

    return (
        <tr className="h-16 border-b-[1px] border-airpark-gray-300">
            <td className="pl-6 whitespace-nowrap">{invoice.invoiceExtId}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.invoiceDate?.substring(0,10)}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.dueDate?.substring(0,10)}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.amountIncVat}</td>
            <td className="pl-6 whitespace-nowrap">{invoice.vat}</td>
            <td className="pl-6 whitespace-nowrap">{invoiceDescription(invoice.invoiceDate)}</td>
            <td className="border-l-[1px] border-airpark-gray-300 w-[72px] min-w-[72px] relative">
                <button className="flex h-16 w-full justify-center items-center" onClick={(e) => { e.preventDefault(); setShowMenu(!showMenu); }}>
                    <ThreeDotsIcon className="block" />
                </button>
                {showMenu &&
                    <TableItemContextMenu onCloseClick={() => { setShowMenu(false); }}>
                        <button className="flex items-center" onClick={(e) => { e.preventDefault(); generateInvoicePDF(invoice); }} >
                            <DownloadIcon className="h-5 w-5" /><span className="ml-1">{t('invoicesTable.downloadPdf')}</span>
                        </button>
                    </TableItemContextMenu>
                }
            </td>
        </tr>
    );
};

function generateInvoicePDF(invoiceData) {
    const doc = new jsPDF();

    doc.setFontSize(12);

    // Company Info
    const companyHeader = [
      "AirPark AB",
      "Redaregatan 48, 252 36 Helsingborg",
      "hello@airpark.app",
      "Organization number: 559350-3138"
    ];

    companyHeader.forEach((text, index) => doc.text(text, 20, 20 + (10 * index)));

    // Invoice general info
    doc.text(`Date: ${invoiceData.invoiceDate?.split("T")[0]}`, 20, 70);
    doc.text(`Due Date: ${invoiceData.dueDate?.split("T")[0]}`, 20, 80);
    doc.text(`Total Amount (inc VAT): ${invoiceData.amountIncVat} SEK`, 20, 100);
    doc.text(`VAT: ${invoiceData.vat} SEK`, 20, 110);
    doc.text(`Description: ${invoiceData.description ?? '-'}`, 20, 120)
    
    // Table for Invoice Lines
    const tableColumn = ["Name", "Email", "Phone", "Area", "Spot", "Start Date", "End Date", "Amount (inc VAT)", "VAT"];
    const tableRows = [];
    
    invoiceData.invoiceLines.forEach(line => {
      const lineData = [
        `${line.firstName} ${line.lastName}`,
        line.email,
        line.phoneNumber,
        line.parkingArea,
        line.parkingSpot,
        line.startDateTime.split("T")[0],
        line.endDateTime.split("T")[0],
        `${line.amountIncVat} SEK`,
        `${line.vat} SEK`,
      ];
      tableRows.push(lineData);
    });

    autoTable(doc, {
        startY: 130,
        head: [tableColumn],
        body: tableRows,
        theme: 'striped',
        headStyles: { fillColor: [51, 208, 133] }, // AirPark green in RGB-color
        styles: { fontSize: 8, cellPadding: 2, overflow: 'linebreak' },
        columnStyles: { text: { cellWidth: 'auto' } }
      });

    // This saves and downloads the PDF
    doc.save(`Invoice_${invoiceData.invoiceExtId}.pdf`);
  }
export default InvoiceItem;